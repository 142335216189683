<template>
  <div class="pt-15 my-10 main-component-content">
    <steps-component/>
    <v-row class="ma-0 px-3">
      <v-container fluid>
        <h1 class="text-left w-full mb-2">Reitemizar</h1>
        <p class="text-left w-full">
          Selecciona un proyecto para ver sus correspondientes solicitudes.
        </p>
        <select-component
          class="mt-3 mb-4"
          placeholder="Selecciona un Proyecto"
          :items="projects"
          itemtext="nombre"
          icon="mdi-arrow-bottom-right-thin-circle-outline"
          :default="project"
          @value="(value) => { project = value; getList(value.id); }"
        />
        <v-row class="ma-0 mb-6 pa-0">
          <v-card elevation="0" class="w-full pa-4">
            <v-row class="pa-0 ma-0 mb-4" justify="space-between" align="center">
              <span>Proyecto: <strong v-if="project">{{ project.nombre }}</strong></span>
              <v-btn
                color="primary-color"
                class="primary-text--text"
                elevation="0"
              >
                Descargar Formulario
              </v-btn>
            </v-row>
            <div id="dropzone" @click="uploadImages()" @dragover.prevent @drop.prevent @drop="handleFileDrop" class="dropzone-box pa-5 border rounded">
              <span v-if="files != null && files.length != 0">
                <div v-for="(file, fileIndex) in files" :key="fileIndex" class="rounded">
                  <span class="p-2" v-if="file.document">
                    {{ file.document.name }}
                  </span>
                </div>
              </span>
              <span class="empty-file-text" v-else ><span>Agregue PDF<br></span>Presione aquí o arrastre el archivo aquí</span>
              <input @change="imageFunction" ref="inputFile" class="hidden" type="file" name="forms" id="input-user-images" accept=".pdf">
            </div>
            <v-btn
              color="primary-color"
              class="primary-text--text mt-4"
              elevation="0"
              @click="sendFile()"
              :disabled="!(files != null && files.length != 0)"
            >
              Subir archivo
            </v-btn>
          </v-card>
        </v-row>
        <v-container fluid class="content">
          <v-row justify="start" align="center" class="pa-0 ma-0 mb-2">
            <strong>Lista de proyectos de la unidad</strong>
          </v-row>
          <v-divider></v-divider>
          <v-card
            elevation="0"
          >
            <v-col cols="12" class="w-full pa-0 ma-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="3">
                  <strong>Nombre</strong>
                </v-col>
                <v-col cols="2">
                  <!-- (no visto/aprobado/rechazado) -->
                  <strong>Estado</strong>
                </v-col>
                <v-col cols="2">
                  <strong>Fecha revisión</strong>
                </v-col>
                <v-col cols="3">
                  <strong>Comentario</strong>
                </v-col>
                <!-- (descargar) -->
                <v-col cols="2">
                  <strong>Extras</strong>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row align="center" v-if="items.length == 0" class="ma-0 pa-0">
                <v-col cols="3">
                  Sin Solicitudes
                </v-col>
              </v-row>
              <v-row align="center" v-for="(item, itemIndex) in items" :key="itemIndex" class="ma-0 pa-0">
                <v-col cols="3">
                  <v-textarea
                    filled
                    name="input-7-4"
                    label="Nombre"
                    color="primary-color"
                    no-resize
                    v-model="item.nombre"
                    readonly
                  ></v-textarea>
                </v-col>
                <v-col cols="2">
                  <v-alert
                    dense
                    text
                    :type="item.estado == 'aprobado' ? 'success' : (item.estado == 'rechazado' ? 'error' : 'warning')"
                    class="ma-0"
                  >
                    {{ item.estado }}
                  </v-alert>
                </v-col>
                <v-col cols="2">
                  <span v-if="item.fecha">{{ item.fecha }}</span>
                  <span v-else>Sin fecha</span>
                </v-col>
                <v-col cols="3">
                  <v-textarea
                    filled
                    name="input-7-4"
                    label="Comentario"
                    color="primary-color"
                    no-resize
                    v-model="item.comentario"
                    readonly
                  ></v-textarea>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    class="secondary-color white--text"
                    width="100%"
                    @click="downloadFile(item)"
                  >
                    descargar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-container>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import Select from '@/components/form/Select.vue';
import StepComponent from '@/components/steps/Stepper.vue';
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
  components: {
    'steps-component': StepComponent,
    'select-component': Select,
  },
  data() {
    return {
      project: null,
      projects: [],
      files: [],
      items: [
        // {
        //   nombre: 'este es el nombre',
        //   estado: 'no visto',
        //   fecha: null, // updated_at
        //   comentario: 'uno dos tres probando',
        //   link: 'test'
        // },
      ]
    };
  },
  created() {
    this.getAllProyecto(this.info.ccValor)
      .then(response => {
        this.projects = response.data.response;
        this.project = this.projects[this.projects.length - 1];
        this.getList(this.project.id);
      })
      .catch(err => console.log(err));
  },
  methods: {
    ...mapActions('main', [
      'getAllProyecto',
      'uploadDocumentoProjectReiterate',
      'getAllReitemizacionByProyecto',
      'downloadReitemizacionById'
    ]),
    getList(id) {
      this.items = [];
      this.getAllReitemizacionByProyecto(id)
        .then(response => {
          this.items = response.data.response;
          this.items.forEach((e,i) => {
            this.items[i].fecha = e.updated_at;
          });
        })
        .catch(err => console.log(err));
    },
    downloadFile(reitemizacion) {
      if(reitemizacion && reitemizacion.id) {
        this.downloadReitemizacionById(reitemizacion.id)
          .then(response => {
            //console.log(response.data.response);
            var saveData = (function () {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                return function (data, fileName) {
                    var json = JSON.stringify(data),
                        blob = new Blob([data], {type: 'application/octet-stream'}),
                        url = window.URL.createObjectURL(blob);
                    json;
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                };
            }());
            saveData(response.data.response, "documento_"+ reitemizacion.nombre);
          })
          .catch(err => console.log(err));
      }
    },
    getDate() {
      Number.prototype.padLeft = function(base,chr){
        var  len = (String(base || 10).length - String(this).length)+1;
        return len > 0? new Array(len).join(chr || '0')+this : this;
      }
      var d = new Date,
      dformat = [(d.getMonth()+1).padLeft(),
                d.getDate().padLeft(),
                d.getFullYear()].join('/') +' ' +
                [d.getHours().padLeft(),
                d.getMinutes().padLeft(),
                d.getSeconds().padLeft()].join(':');
      return dformat;
    },
    uploadImages() {
      document.getElementById('input-user-images').click()
    },
    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files
      if(!droppedFiles) return
      this.saveFiles(droppedFiles)
    },
    imageFunction() {
      let inputFiles = this.$refs.inputFile.files
      this.saveFiles(inputFiles)
    },
    saveFiles(files) {
      this.files = []
      for (let inputFile of files) {
        let reader = new FileReader()
        let tempFile = {
          url: '',
          document: ''
        }
        reader.addEventListener("load", function() {
          tempFile.url = reader.result
        }.bind(this), false)
        if(inputFile) {
          if ( /\.(pdf)$/i.test(inputFile.name) ) {
            reader.readAsDataURL(inputFile);
            tempFile.document = inputFile
            this.files.push(tempFile)
          } else {
            // the image format is not supported
            // image is not png | jpg | jpeg
            // not added
          }
        }
      }
    },
    sendFile() {
      if (!(this.files && this.files.length != 0)) {
        Swal.fire({
          title: 'Por favor selecciona un archivo.',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Volver',
          icon: 'warning',
        })
        return;
      }
      this.uploadDocumentoProjectReiterate({
        idCentroCosto: this.info.id,
        id: this.project.id,
        file: this.files[0]
      })
        .then(response => {
          console.log(response.data.response);
          Swal.fire({
            title: 'Archivo envíado.',
            confirmButtonColor: '#EA7600',
            confirmButtonText: 'Volver',
            icon: 'success',
          })
        })
        .catch(err => console.log(err));
    },
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info']
    }),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
};
</script>

<style scoped>
.content {
  background-color: white;
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dedede;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.content-footer {
  position: relative;
  width: 90%;
  max-width: 1500px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.main-component-content {
  min-height: 100vh;
  width: 100%;
}
.welcome-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.welcome-link {
  cursor: pointer;
  text-decoration: underline;
}
.welcome-link:not(:last-child) {
  margin-right: 1em;
}
.border-t {
  border-top: 1px solid rgba(0,0,0,.12);
}
.border-r {
  border-right: 1px solid rgba(0,0,0,.12);
}
.text-transform-none {
  text-transform: none;
}
.home-background-card {
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.web-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.web-grid-profile {
  @media only screen and (min-width: $mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: $mobile - 1px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.web-grid div::before,
.web-grid-profile div::before {
  content: '';
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.empty-file-text {
  font-size: 12px;
  color: #2e2c2c;
}

.border {
  border: 1px solid #dedede;
}

.dropzone-box {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.square-background {
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 12px;
}
</style>
